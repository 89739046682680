import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function FullStackSoftwareEngineer () {
  return (
    <CareerLayout title='Market Lead' location='Ottawa, Winnipeg'>
      <h2>Overview</h2>
      <p>Trexity is looking for someone who is passionate about supporting local small and medium sized merchants by providing them with the logistics tools to compete with the big box stores of the world. We are looking for market leads in Ottawa and Winnipeg!</p>
      <p>The Market Lead will be a key player in growing a market from the ground up. You will own your market and work cross functionally with every part of our business to ensure success. You must have the ability (or a willingness to learn) to build strong relationships, sell when necessary and make decisions based on the qualitative and quantitative data available. When safe to do so, you will be required to meet in-person with merchants and couriers. This role reports to the General Manager.</p>
      <p className='mb'>Trexity’s mission is to allow small and medium-sized merchants to compete against the headless and soulless retail behemoths by better servicing their customers. Trexity makes it easy to close the logistics gap and allows merchants to once again own their customer’s doorstep. If that’s a vision you want to turn into reality, then we want to meet you.</p>

      <h2>Responsibilities</h2>
      <ul className='trx-bullet mb'>
        <li>Understand the P&amp;L to achieve growth while keeping within budget</li>
        <li>Achieve growth goals (deliveries, merchants, revenue)</li>
        <li>Strengthen and grow the courier community via events, promotions, communications, and training</li>
        <li>Build the merchant base through local marketing efforts, including building partnerships, managing brand ambassadors, and helping localize large-scale promotion</li>
        <li>Synthesize market feedback and recommendations to improve our offering</li>
        <li>Identify process gaps and support in creating a solution</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>1-2 years of operations, customer success, sales or business development experience</li>
        <li>Entrepreneurial mindset with a get-it-done attitude</li>
        <li>Quantitative by nature and obsessed with process improvement</li>
        <li>Experience working cross-functionally</li>
        <li>Organized, there will be a lot of moving pieces to be aware of</li>
        <li>Clear communicator</li>
        <li>Tech savvy and do not shy away from learning new programs</li>
        <li>Deep region knowledge and network</li>
        <li>Valid driver’s license and access to a vehicle</li>
      </ul>

      <h3>Bonus points</h3>
      <ul className='trx-bullet mb'>
        <li>Two-sided market experience</li>
        <li>Start-up experience</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
